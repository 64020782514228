import isNil from "lodash/isNil";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import without from "lodash/without";
import {
  AxisTypeValue,
  DashStyleValue,
  PointMarkerOptionsObject,
  SeriesArearangeOptions,
  SeriesBarOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SymbolKeyValue
} from "highcharts";

import { ChartSeriesOptions, RawChartSeries } from "../Chart/types";
import { MhcTimeSeries } from "graphqlApi/types";

import { demographicColors, yellowGreenBlueViolet } from "theme/colors";
import { CHART_COLORS, colorConfig, defaultChartColorSet } from "../util/color/config";
import { OVERALL_NAME } from "common/components/charts/Investigate/util";
import { NO_DATA_COLOR } from "common/components/charts/util/color";
import { dateAsTime } from "common/util/date";
import { getUtcDateNumber } from "common/util/utcDateFromString";
import { LoadedStat } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";

export const MULTI_INVESTIGATION_DELIMITER = ";";
export const CHART_MARKERS: SymbolKeyValue[] = [
  "circle",
  "square",
  "triangle",
  "diamond",
  "triangle-down",
  "circle"
];
export const CHART_LINE_STYLE: DashStyleValue[] = [
  "Solid",
  "LongDash",
  "LongDashDot",
  "Dot",
  "Dash",
  "DashDot",
  "ShortDash",
  "LongDashDotDot"
];

/**
 * Create date time series from MhcTimeSeries where the date is converted to time
 *
 * @param dateTimeSeries
 *
 * @returns new time series where the date is an integer representing state a time
 */
export const seriesData = (
  dateTimeSeries: Omit<MhcTimeSeries, "timestamps"> & { timestamps?: MhcTimeSeries["timestamps"] }
): [number, number][] => {
  const { values = [], dates = [], timestamps = [] } = dateTimeSeries;
  const series = dates.length ? dates : timestamps;
  return series.map(
    (date: number | string, i: number) =>
      [dateAsTime(date) ?? null, values[i] ?? null] as [number, number]
  );
};

interface Params {
  series: RawChartSeries[];
  seriesType?: AxisTypeValue;
  markersOn?: boolean;
  chartType?: "line" | "spline" | "arearange";
  showConfidenceIntervals?: boolean;
  isAgeSeries?: boolean;
}

export const isRenderableChartSeries = (series: ChartSeriesOptions[]) => {
  return series.some(({ data }) => {
    if (!data) return false;
    return data.length > 1;
  });
};

const getLargestNumber = (str: string): number | null => {
  // Replace non-numeric characters except '-' when it appears as a negative sign
  const cleanedStr = str.replace(/[^0-9-]+/g, "");

  // Extract numbers from the cleaned string
  const numbers = cleanedStr.match(/-?\d+/g) || [];

  // Convert extracted strings to numbers
  const numberArray = numbers.map(Number);

  // Find and return the largest number
  return numberArray.length > 0 ? Math.max(...numberArray) : null;
};

export const colorFromUnitLabel = (name?: string): string | undefined => {
  const toEvaluate = `${name?.toLowerCase() ?? ""}`;
  const { race = {}, ethnicity = {}, sex = {}, grade = {} } = demographicColors;
  if (toEvaluate?.includes("total")) return "#aaa";
  if (toEvaluate?.includes("white")) return race.white;
  if (toEvaluate?.includes("black")) return race.black;
  if (toEvaluate?.includes("asian")) return race.asian;
  if (toEvaluate?.includes("multiple race") || toEvaluate?.includes("multiracial"))
    return race.multiracial;
  if (toEvaluate?.includes("other race")) return race.otherRace;
  if (toEvaluate?.includes("native american")) return race.nativeAmerican;
  if (toEvaluate?.includes("pacific islander") || toEvaluate?.includes("asian or pacific islander"))
    return race.pacificIslander;
  if (toEvaluate?.includes("non-hispanic")) return ethnicity.nonHispanic;
  if (toEvaluate?.includes("hispanic")) return ethnicity.hispanic;
  if (toEvaluate?.includes("female")) return sex.female;
  if (toEvaluate?.includes("male")) return sex.male;
  if (toEvaluate?.includes("patient declined to disclose")) return "#EC829B";
  if (toEvaluate?.includes("grade 8")) return grade.eight;
  if (toEvaluate?.includes("grade 11")) return grade.eleven;
  if (toEvaluate?.includes("unknown")) return NO_DATA_COLOR;
  if (toEvaluate?.includes("data not reported")) return NO_DATA_COLOR;
  return undefined;
};

/**
 * Builds series for line chart from data with properties Highcharts expects
 *
 * @param params
 * @param params.series - Array of series data as LineChartSeries
 * @param params.seriesType - Series type (datetime or category)
 * @param params.markersOn - Whether to show markers on the series
 * @param params.chartType - Chart type (line or spline)
 * @param params.isAgeSeries - Determines if the provided series is of age ranges
 *
 * @param params.showConfidenceIntervals
 * @returns Line or Spline chart series options per series
 * @see LineChartSeries
 *
 */
export const seriesForLineChart = ({
  series,
  seriesType = "datetime",
  markersOn = true,
  chartType = "line",
  showConfidenceIntervals = false,
  isAgeSeries = false
}: Params) => {
  const getAllSeries = () => {
    if (isAgeSeries === false) return series;

    let ageSeries = series
      .filter(({ name, ...series }) => {
        const number = getLargestNumber(name ?? "");
        return number !== null && number <= 100 && series && series.values.length > 0;
      })
      .sort(
        (a, b) => (getLargestNumber(a.name ?? "") ?? 0) - (getLargestNumber(b.name ?? "") ?? 0)
      );
    ageSeries = ageSeries.map((props, index) => {
      let color = yellowGreenBlueViolet[index];
      if (ageSeries.length <= 6) color = yellowGreenBlueViolet[index * 2];
      return { ...props, color };
    });
    const nonAgeSeries = series.filter(({ name }) => {
      const number = getLargestNumber(name ?? "");
      return !(number !== null && number <= 100);
    });
    return [...ageSeries, ...nonAgeSeries];
  };
  return getAllSeries().flatMap((props, i) => {
    const {
      dates = [],
      timestamps = [],
      values,
      name,
      confidenceIntervals = [],
      color,
      dashStyle,
      type,
      accessibility,
      lineWidth,
      visible = true,
      ...currentProps
    } = props;
    const _series: ChartSeriesOptions[] = [
      {
        color: color || colorFromUnitLabel(name) || colorConfig.set[i],
        dashStyle: dashStyle ?? undefined,
        type: type || chartType || "line",
        pointInterval: 1,
        data:
          seriesType === "category"
            ? (values as number[])
            : seriesData({
                dates,
                timestamps,
                values
              }),
        name,
        lineWidth: lineWidth ?? 3,
        accessibility: accessibility,
        marker: {
          enabled: markersOn,
          radius: 5,
          symbol: "circle"
        },
        visible,
        ...currentProps
      } as SeriesLineOptions
    ];
    if (showConfidenceIntervals && confidenceIntervals.length) {
      _series.push({
        id: [name?.toLowerCase()?.replaceAll(" ", "-"), "confidence", "intervals"].join("-"),
        name: `${name ?? ""} - Confidence Intervals`,
        linkedTo: ":previous",
        data: confidenceIntervals.map(({ lower, upper }: { lower: number; upper: number }, i) => [
          getUtcDateNumber(dates[i]) ?? i,
          lower,
          upper
        ]),
        type: "arearange",
        lineWidth: 0,
        fillOpacity: 0.2,
        color: color || colorFromUnitLabel(name) || colorConfig.set[i],
        showInLegend: false,
        marker: {
          enabled: false
        },
        point: {
          events: {
            mouseOver: function (event) {
              event.preventDefault();
              event.stopPropagation();
            }
          }
        },
        states: {
          hover: {
            enabled: false
          }
        },
        ...currentProps
      } as SeriesArearangeOptions);
    }
    return _series;
  });
};

export type ChartSeriesOptionsDictionaryItem = ChartSeriesOptions & {
  format?: "category" | "timeseries";
  lineWidth?: number;
  marker?: PointMarkerOptionsObject;
};
export type ChartSeriesOptionsDictionary = Record<string, ChartSeriesOptionsDictionaryItem>;
export type StatsToSeriesParams = {
  stats: (LoadedStat & { _id?: string | null })[];
  options: ChartSeriesOptionsDictionary;
  useIdAsName?: boolean;
};

/**
 * Converts an array of locationStats (MhcLocationStatWithDataSeriesFragment) to an array of
 * Highchart series options objects
 *
 * @param params
 * @param params.stats - Array of MhcLocationStatWithDataSeriesFragment objects
 * @param params.options - Options that will be applied to each series
 * @param params.useIdAsName - Determines if the id of each stat should be used as the name
 *
 * @returns Array of Highchart series options objects
 *
 * @see MhcLocationStatWithDataSeriesFragment
 * @see ChartSeriesOptions
 *
 */
export const statsToSeries = ({ stats, options, useIdAsName }: StatsToSeriesParams) => {
  return stats.map(({ statIdentifier, timeSeries, id, _id, location }, i) => {
    const { name, type, format, color, dashStyle, lineWidth, marker, ...additionalOptions } =
      (options[statIdentifier.id ?? ""] ||
        options[[statIdentifier.id, location?.id ?? ""].join("-")]) ??
      {};
    const { values = [], dates = [] } = timeSeries ?? {};
    return {
      _id,
      color: color || defaultChartColorSet[i],
      dashStyle: dashStyle ?? null,
      type: type ?? "line",
      data:
        format === "category"
          ? (values as number[])
          : seriesData({
              dates,
              timestamps: [],
              values
            }),
      name: useIdAsName ? id : name ?? "",
      lineWidth: lineWidth ?? 3,
      marker: marker ?? {
        enabled: true,
        radius: 5,
        symbol: "circle"
      },
      visible: true,
      ...additionalOptions
    } as SeriesLineOptions & { _id?: string | null };
  });
};

type SeriesInfo = {
  data: Array<number | null>;
  categories: string[];
  colors: string[];
};

type StatsToCategoricalSeries = {
  stats: LoadedStat[];
  name?: string;
  overallStatId?: string;
  type: "column" | "bar";
  sortSeries?: boolean;
  multiInvestigation?: boolean;
};

export const statsToCategoricalSeries = ({
  stats,
  name,
  overallStatId,
  type = "column",
  sortSeries = true,
  multiInvestigation = false
}: StatsToCategoricalSeries): {
  series: Array<SeriesBarOptions | SeriesColumnOptions>;
  categories: string[];
} => {
  const overallStat = stats.find(
    ({ statIdentifier }: LoadedStat) => statIdentifier?.id === overallStatId
  );
  // If overallStat remove from list of stats
  // so we can add it later to render it at the end of the series
  let sortedStats = overallStat ? without(stats, overallStat) : stats;
  const isSortable =
    sortSeries &&
    !stats.some(({ statIdentifier }) => statIdentifier.stratumGroup?.match(/age|education/));
  if (isSortable) {
    sortedStats = sortBy(sortedStats, ({ lastValue }) => (isNil(lastValue) ? 0 : lastValue * -1));
  }
  const {
    data,
    categories,
    colors = []
  } = sortedStats.reduce(
    (acc, { lastValue, statIdentifier }) => {
      const { name, stratumGroup } = statIdentifier;
      let color = defaultChartColorSet[0];
      if (
        stratumGroup &&
        Object.keys(demographicColors).some((colorKey) => stratumGroup.match(new RegExp(colorKey)))
      ) {
        color = colorFromUnitLabel(name) ?? color;
      }
      let seriesName = name;
      if (multiInvestigation && name.includes(MULTI_INVESTIGATION_DELIMITER)) {
        seriesName = last(name.split(MULTI_INVESTIGATION_DELIMITER))?.trim() ?? name;
      }
      acc.categories.push(seriesName);
      acc.data.push(lastValue ?? null);
      acc.colors.push(color ?? (defaultChartColorSet[0] as string));
      return acc;
    },
    { data: [], categories: [], colors: [] } as SeriesInfo
  );

  // Add the overall stat (if available) to all the arrays so it appears last
  // with the primary chart
  if (overallStat) {
    data.push(overallStat.lastValue ?? null);
    categories.push(OVERALL_NAME);
    colors.push(colorConfig.comparison as string);
  }

  return {
    categories,
    series: [
      {
        name,
        type,
        data,
        showInLegend: false,
        colors:
          stats.length > CHART_COLORS.length ? (colorConfig.moreThanTenPoints as string[]) : colors,
        colorByPoint: !!colors.length && stats.length < CHART_COLORS.length
      }
    ]
  };
};
